// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-containers-page-index-js": () => import("/Users/andrezimpel/Sites/unknown-x/src/containers/page/index.js" /* webpackChunkName: "component---src-containers-page-index-js" */),
  "component---src-containers-news-index-js": () => import("/Users/andrezimpel/Sites/unknown-x/src/containers/news/index.js" /* webpackChunkName: "component---src-containers-news-index-js" */),
  "component---src-pages-404-js": () => import("/Users/andrezimpel/Sites/unknown-x/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/andrezimpel/Sites/unknown-x/.cache/data.json")

