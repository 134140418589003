module.exports = [{
      plugin: require('/Users/andrezimpel/Sites/unknown-x/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-63717815-18","head":false,"anonymize":true},
    },{
      plugin: require('/Users/andrezimpel/Sites/unknown-x/node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://82d2f6caabd243e6815861c87de11e99@sentry.io/1195365","environment":"production","enabled":true},
    },{
      plugin: require('/Users/andrezimpel/Sites/unknown-x/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
